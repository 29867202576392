import React from 'react';
import logo from './logo.svg';
import logoS3 from './logoS3.png';
import logoCF from './logoCloudFront.png';
import './App.css';

// To be replaced by the endpoint of the API deployed through the CloudFormation Template
const APIEndPoint = 'http://5600-consulting-mainsite.s3-website-us-east-1.amazonaws.com'

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-MainLogo" alt="logo" />
            </header>
            <p>This react-based application is hosted in an S3 bucket exposed through a CloudFront distribution</p>
            <div className="logos">
                <img src={logoS3} className="App-logoR2L" alt="logo S3" />
                <img src={logoCF} className="App-logoL2R" alt="logo CloudFront" />
            </div>
        </div>
    );
}


export default App;
